import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Layout from "./PageComponents/Layout";
import About from "./Pages/About";
import 'reactjs-popup/dist/index.css';
import Contact from "./Pages/Contact";
import DavidRaysDampproofingPage from "./Pages/DavidRaysDampproofingPage";
import SectionInsightsPage from "./Pages/SectionInsightsPage";
import FearlessCmdfPage from "./Pages/FearlessCmdfPage";
import BCWhalesIncidentReporterPage from "./Pages/BCWhalesIncidentReporterPage";
import PageNotFound from "./Pages/PageNotFound";

function App() {
  return (
      <>
          <BrowserRouter>
              <Routes>
                  <Route path="/" element={ <Layout /> }>
                      <Route index element={<About/>}/>
                      <Route path="contact" element={<Contact/>}/>
                      <Route path="dr-damproofing-project" element={<DavidRaysDampproofingPage/>}/>
                      <Route path="sections-insight-project" element={<SectionInsightsPage/>}/>
                      <Route path="bcwhales-incident-reporter-project" element={<BCWhalesIncidentReporterPage/>}/>
                      <Route path="fearless-cmdf-project" element={<FearlessCmdfPage/>}/>
                      <Route
                          path="*"
                          element={<PageNotFound />}
                      />
                  </Route>
              </Routes>
          </BrowserRouter>
      </>
);
}
export default App;
