import NavLinks from "./NavLinks";
import './navbar.css';
import {Link} from "react-router-dom";

const DesktopNavigation = () =>{
    return(
        <nav className="DesktopNavigation">
            <Link to="/" className="navbar-logo">jezarah ebel.</Link>
            <NavLinks />
            <div style={{marginLeft: "auto"}}>
                <Link className="responsive-button" to="/contact">Contact Me</Link>
            </div>
        </nav>
    );
}

export default DesktopNavigation;