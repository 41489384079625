
const PageNotFound  = () => {
    return (
        <>
            <div className="page" style={{textAlign: "center"}}>
                404 | This page could not be found.
            </div>
        </>
    );
}
export default PageNotFound;