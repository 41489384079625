import './navbar.css';
import {Link} from "react-router-dom";
import {useState} from "react";
import {MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp} from "react-icons/md";
const NavLinks = ({isClicked, closeMenu}) => {
    const [isOpen, setIsOpen] = useState(false);
    function changeDropDownState () {
        setIsOpen(!isOpen);
    }
    function closeDropDownState () {
        setIsOpen(false);
    }

    return(
        <nav className="NavLinks">
            <ul className="nav-ul">
                <div className="nav-ul-li">
                    <div>
                        <li className="MobileVisibility" onClick={() => isClicked && closeMenu()}>
                            <Link className="nav-ul-li-a" to="/" onClick={closeDropDownState}>Home</Link>
                        </li>
                        <li className="pointer nav-ul-li-a" onClick={() => changeDropDownState()}>
                            My Projects
                            {!isOpen && <MdOutlineKeyboardArrowDown/>}
                            {isOpen && <MdOutlineKeyboardArrowUp/>}
                        </li>
                        {isOpen &&
                            <div className="nav-ul-li-sub-menu-group">
                                <li className="nav-ul-li-sub-menu-item nav-dropdown" onClick={() => isClicked && closeMenu()}>
                                    <Link className="nav-ul-li-a" to="/sections-insight-project" onClick={closeDropDownState}>Section Insights</Link>
                                </li>
                                <li className="nav-ul-li-sub-menu-item nav-dropdown" style={{paddingRight: 4}} onClick={() => isClicked && closeMenu()}>
                                    <Link className="nav-ul-li-a" to="/dr-damproofing-project" onClick={closeDropDownState}>David Ray's Dampproofing Website</Link>
                                </li>
                                <li className="nav-ul-li-sub-menu-item nav-dropdown" onClick={() => isClicked && closeMenu()}>
                                    <Link className="nav-ul-li-a" to="/bcwhales-incident-reporter-project" onClick={closeDropDownState}>BCWhales Remote Report App</Link>
                                </li>
                                <li className="nav-ul-li-sub-menu-item nav-dropdown nav-dropdown-last" onClick={() => isClicked && closeMenu()}>
                                    <Link className="nav-ul-li-a" to="/fearless-cmdf-project" onClick={closeDropDownState}>Fearless - cmd-f Hackathon</Link>
                                </li>
                            </div>
                        }
                    </div>
                    <li className="MobileVisibility" onClick={() => isClicked && closeMenu()}>
                        <Link className="nav-ul-li-a" to="/contact" onClick={closeDropDownState}>Contact Me</Link>
                    </li>
                </div>
            </ul>
        </nav>
    )
}

export default NavLinks;